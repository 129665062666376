<template>
  <div class="bg-shop-summary">
    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          营收数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            style="width: 220px;"
            v-model="daterangeDate"
            type="daterange"
            @change="daterangeDateChange"
            range-separator="-"
            size="medium"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          />
        </div>
        <div class="col-auto">
          <el-button size="small" type="primary" @click="handleSearch()">搜索</el-button>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading" @click="downZd()">导出{{typeList[type - 1].label}}营收数据</el-button>
        </div>
      </div>

      <div class="btnTab" style="width:30%;margin:0 auto;margin-bottom:-30px;">
        <el-button
          size="small"
          :type="type === item.value ? 'primary' : 'default'"
          @click="handleSearch(item.value)"
          v-for="(item,index) in typeList"
          :key="index"
        >{{ item.label }}
        </el-button>
      </div>
      <div id="priceList" :style="{height: '50vh'}"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          月度营收统计
        </div>
        <div class="col-auto">
          <el-date-picker
            size="medium"
            v-model="timeMonth"
            @change="changeTime"
            type="month"
            placeholder="请选择月份"
            :clearable="false"
          />
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading1" @click="downZd1()">导出月度营收统计</el-button>
        </div>
      </div>

      <en-table-layout :toolbar="false" :pagination="false" :tableData="pageData.data" :loading="loading">
        <template slot="table-columns">
          <el-table-column label="日期">
            <template slot-scope="scope">
              {{ scope.row.year ? (scope.row.year + '-') : '' }}
              {{ scope.row.month ? (scope.row.month + '-') : '' }}
              {{ scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column prop="total_money" label="日新增营收(元)"/>
          <el-table-column prop="sum_price" label="供应商累计营收(元)"/>
        </template>
      </en-table-layout>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          年度营收统计
        </div>
        <div class="col-auto">
          <el-date-picker
            size="medium"
            v-model="timeYear"
            @change="changeTime1"
            type="year"
            placeholder="请选择年份"
            :clearable="false"
          />
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button type="text" :loading="importLoading2" @click="downZd2()">导出年度营收统计</el-button>
        </div>
      </div>

      <en-table-layout :toolbar="false" :pagination="false" :tableData="pageData1.data" :loading="loading">
        <template slot="table-columns">
          <el-table-column label="日期">
            <template slot-scope="scope">
              {{ scope.row.year ? (scope.row.year + '-') : '' }}
              {{ scope.row.month ? (scope.row.month + '-') : '' }}
              {{ scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column prop="total_money" label="月新增营收(元)"/>
          <el-table-column prop="sum_price" label="供应商累计营收(元)"/>
        </template>
      </en-table-layout>
    </el-card>
  </div>
</template>

<script>
import * as API_buyAnyalysis from '@/api/buyAnyalysis';
import {CategoryPicker} from '@/components';
import {Foundation} from '@/../ui-utils';
import {handleDownload} from '@/utils';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'buyAnalysis',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      type: 1,
      timeMonth: new Date(),
      timeYear: new Date(),
      importLoading: false,
      importLoading1: false,
      importLoading2: false,
      typeList: [
        {
          label: '新增',
          value: 1
        },
        {
          label: '累计',
          value: 2
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近7日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近15日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近30日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      /** 列表loading状态 */
      loading: false,

      daterangeDate: '',

      pageData1: {data: []},
      pageData: {data: []},

      /** 图表参数*/
      params: {},
      data: {},

      tableHeight: document.body.clientHeight / 2,
      time: {
        monthTime: new Date(),
        yearTime: new Date(),
      }
    };
  },
  created() {
    this.resetTimeParams();
    this.GET_PriceStatistics();
    this.GET_PriceStatistics1(
      'MONTH',
      Foundation.unixToDate(this.params.end_time, 'yyyy'),
      Foundation.unixToDate(this.params.end_time, 'M')
    );
    this.GET_PriceStatistics1(
      'YEAR',
      Foundation.unixToDate(this.params.end_time, 'yyyy')
    );
  },
  mounted() {
    window.onresize = this.countTableHeight;
    this.$nextTick(() => {
      this.priceListChart = this.$echarts.init(
        document.getElementById('priceList')
      );
    });
  },
  methods: {
    resetTimeParams() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

      this.params.start_time = parseInt(start / 1000);
      this.params.end_time = parseInt(end / 1000);
    },
    downZd() {
      let data = {...this.data};
      this.importLoading = true;
      let response = [];
      data.xAxis.forEach(item => {
        response.push({date: item});
      });
      data.series.data.forEach((item, index) => {
        response[index]['ys'] = item;
      });
      let tHeaders = ['日期', '营收'];
      let filterVals = ['date', 'ys'];
      console.log(response);
      handleDownload(
        response,
        tHeaders,
        filterVals,
        this.type === 1 ? '新增营收数据' : '累计营收数据'
      );
      this.importLoading = false;
    },
    downZd1() {
      this.importLoading1 = true;
      let tHeaders = ['日期', '日新增营收（元）', '供应商累计营收（元）'];
      let filterVals = ['timeMonth', 'sum_price', 'total_money'];
      const name = `${this.time.monthTime.getFullYear()}年${this.time.monthTime.getMonth() + 1}月营收统计`;
      let exportData = [...this.pageData.data];
      exportData.forEach(i => {
        i.timeMonth = (i.year ? (i.year + '-') : '') + ( i.month ? (i.month + '-') : '' ) + (i.time);
      })
      handleDownload(exportData, tHeaders, filterVals, name);
      this.importLoading1 = false;
    },
    downZd2() {
      this.importLoading2 = true;
      let tHeaders = ['日期', '月新增营收（元）', '供应商累计营收（元）'];
      let filterVals = ['timeYear', 'sum_price', 'total_money'];
      const name = `${this.time.yearTime.getFullYear()}年营收统计`;
      let exportData = [...this.pageData1.data];
      exportData.forEach(i => {
        i.timeYear = (i.year ? (i.year + '-') : '') + ( i.month ? (i.month + '-') : '' ) + (i.time);
      })
      handleDownload(exportData, tHeaders, filterVals, name);
      this.importLoading2 = false;
    },
    changeTime(end) {
      this.time.monthTime = new Date(end);
      this.GET_PriceStatistics1(
        'MONTH',
        Foundation.unixToDate(parseInt(end / 1000), 'yyyy'),
        Foundation.unixToDate(parseInt(end / 1000), 'M')
      );
    },
    changeTime1(end) {
      this.time.yearTime = new Date(end);
      this.GET_PriceStatistics1(
        'YEAR',
        Foundation.unixToDate(parseInt(end / 1000), 'yyyy')
      );
    },
    daterangeDateChange(val) {
      if (val) {
        this.params.start_time = parseInt(val[0] / 1000);
        this.params.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams();
      }
      this.GET_PriceStatistics();
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight / 2;
      /** 图表刷新 */
      setTimeout(this.priceListChart.resize);
    },

    /** 执行搜索 */
    handleSearch(type) {
      if (type) this.type = type;
      this.GET_PriceStatistics();
    },

    GET_PriceStatistics() {
      let func = this.type == 1 ? 'orderNewMoney' : 'orderAccMoney';
      API_buyAnyalysis[func](this.params).then(response => {
        this.data = response;
        this.priceListChart.setOption({
          tooltip: {trigger: 'axis'},
          grid: {
            x: '50px',
            y: '80px',
            x2: '60px',
            y2: '30px'
          },
          legend: {
            show: true,
            orient: 'vertical',
            data: [{name: '金额'}],
            bottom: '10px'
          },
          //   color: ["#7CB5EC"],
          toolbox: {
            feature: {
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: '日期',
            type: 'category',
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: '金额',
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              type: 'line',
              data: response.series.data,
              name: '营收',
              symbol: 'none', //这句就是去掉点的
              smooth: true, //这句就是让曲线变平滑的
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1A43A9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#9083EC'
                    },
                    {
                      offset: 1,
                      color: '#6EC2F1'
                    }
                  ])
                }
              },
              areaStyle: {normal: {}},
              symbolSize: 0,
              barWidth: 50
            }
          ]
        });
      });
    },
    GET_PriceStatistics1(cycle_type, year, month) {
      API_buyAnyalysis.orderTurnover({
        cycle_type,
        year,
        month
      }).then(
        response => {
          if (month) {
            response.forEach(item => {
              item.year = year;
              item.month = month;
            })
            this.pageData.data = response;
          } else {
            response.forEach(item => {
              item.year = year;
              item.month = month;
            })
            this.pageData1.data = response;
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.btnTab {
  margin-bottom: -30px;
  position: relative;
  text-align: center;
  z-index: 100;
  width: 100%;
}
</style>

